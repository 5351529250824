import api from './api'
import { Token, Failure } from './types'

export interface SignInParams {
  login: string
  password: string
}

export interface SignInSuccess {
  phoneConfirmationId: string
  maskedPhoneNumber: string
}

export const signIn = async (data: SignInParams) => {
  return api<SignInSuccess, Failure>({
    url: '/auth/signin',
    method: 'POST',
    data,
  })
}

export interface ConfirmSmsCodeParams {
  phoneConfirmationId: string
  code: string
}

export interface ConfirmSmsCodeSuccess {
  token: Token
}

export const confirmSmsCode = async (data: ConfirmSmsCodeParams) => {
  return api<ConfirmSmsCodeSuccess, Failure>({
    url: '/auth/signin/$confirm',
    method: 'POST',
    data,
  })
}
