import { useState, useEffect } from 'react'

const SECOND = 1000

export default function (seconds: number): [number, () => void] {
  const [timer, setTimer] = useState(seconds)

  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1)
      } else {
        clearInterval(interval)
      }
    }, SECOND)

    return () => clearInterval(interval)
  }, [timer])

  const resetTimer = () => setTimer(seconds)

  return [timer, resetTimer]
}
