import { useStaticQuery, graphql } from 'gatsby'

type LegalDocumentNode = {
  slug: string
  title: string
}

type QueryResponse = {
  allContentfulLegalDocument: {
    nodes: LegalDocumentNode[]
  }
}

export default function useLegalDocuments() {
  const {
    allContentfulLegalDocument: { nodes: documents },
  } = useStaticQuery<QueryResponse>(graphql`
    query {
      allContentfulLegalDocument {
        nodes {
          slug
          title
        }
      }
    }
  `)

  return documents
}
