import { AxiosRequestConfig } from 'axios'
import { client } from './client'

enum Status {
  Success = 'Success',
  Failure = 'Failure',
}

export interface Success<S> {
  status: Status.Success
  data: S
}

export interface Failure<F> {
  status: Status.Failure
  error: F
}

export type Response<S = any, F = any> = Success<S> | Failure<F>

function handleSuccess<S = any>(data: S): Success<S> {
  return {
    status: Status.Success,
    data,
  }
}

function handleFailure<F = any>(error: F): Failure<F> {
  return {
    status: Status.Failure,
    error,
  }
}

export function isSuccess<S>(res: Response<S>): res is Success<S> {
  return res.status === Status.Success
}

export function isFailure<F>(res: Response<any, F>): res is Failure<F> {
  return res.status === Status.Failure
}

export default async function api<S = any, F = any>(
  config: AxiosRequestConfig,
): Promise<Response<S, F>> {
  try {
    const response = await client(config)

    return handleSuccess(response.data)
  } catch (e) {
    return handleFailure(e.response ? e.response.data : e.message)
  }
}
