import { useStaticQuery, graphql } from 'gatsby'

type GenericPageNode = {
  slug: string
  title: string
}

type QueryResponse = {
  allContentfulGenericPage: {
    nodes: GenericPageNode[]
  }
}

export default function useGenericPages() {
  const {
    allContentfulGenericPage: { nodes: genericPages },
  } = useStaticQuery<QueryResponse>(graphql`
    query {
      allContentfulGenericPage(
        filter: { slug: { nin: ["bodycomp", "beta"] } }
        sort: { fields: [title], order: ASC }
      ) {
        nodes {
          slug
          title
        }
      }
    }
  `)

  const aboutPage = genericPages.find(({ slug }) => slug === 'about')
  const downloadPage = genericPages.find(({ slug }) => slug === 'download')
  const financePages = genericPages.filter(
    ({ slug }) => slug === 'investors' || slug === 'ventures',
  )

  return { aboutPage, downloadPage, financePages }
}
