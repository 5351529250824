import { useEffect } from 'react'
import { DateTime } from 'luxon'

import { useAuthentication, useFetch } from 'hooks'
import { getUserInfo, UserInfo, getAidboxResource } from 'services/api'
import { useUserContext, User } from 'contexts/UserProvider'

type UserPhoto = {
  creation: string
  url: string
}

type AidboxPatientResource = {
  id: string
  resourceType: string
  meta: {
    lastUpdated: string
    createdAt: string
    version: string
  }
  name: { family: string; given: string[] }[]
  birthDate: string
  photo?: UserPhoto[]
}

type AidboxPatientReference = {
  id: string
  resourceType: string
}

function formatBirthDate(date: string | undefined): string {
  if (typeof date === 'undefined') return 'N/A'
  else {
    const dateTime: DateTime = DateTime.fromISO(date)
    const age: number = Math.abs(
      Math.ceil(dateTime.diffNow('year').get('year')),
    )
    return `${dateTime.toLocaleString(DateTime.DATE_FULL)} (${age} years)`
  }
}

function extractUserFromResponse({
  userInfo,
  patient,
}: {
  userInfo: UserInfo
  patient: AidboxPatientResource
}): User {
  const {
    data: {
      patient: { id: patientId },
      bodyComp,
    },
    id,
    email,
    phoneNumber,
  } = userInfo

  const { birthDate, name, photo } = patient

  const firstName = name[0].given.map(n => n.trim()).join(' ')
  const lastName = name[0].family.trim()

  return {
    id,
    email,
    firstName,
    lastName,
    patientId,
    phoneNumber,
    birthDate: formatBirthDate(birthDate),
    photo: photo && photo[0].url,
    bodyComp,
  }
}

export default function useUserLoading() {
  const { data, isLoaded, isLoading, setData, setIsLoaded, setIsLoading } =
    useUserContext()
  const { isAuthenticated } = useAuthentication()

  const fetchUser = useFetch(getUserInfo)
  const fetchPatient = useFetch(async (patient: AidboxPatientReference) => {
    return getAidboxResource<AidboxPatientResource>(patient)
  })

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      setIsLoading(true)
      fetchUser.fetch({})
    }
  }, [isLoaded, isLoading, isAuthenticated])

  useEffect(() => {
    if (fetchUser.isFetched && fetchUser.isFetchSuccessful && fetchUser.data) {
      fetchPatient.fetch(fetchUser.data.data.patient)
    }
  }, [fetchUser.isFetched, fetchUser.isFetchSuccessful, fetchUser.data])

  useEffect(() => {
    if (
      fetchPatient.isFetched &&
      fetchPatient.isFetchSuccessful &&
      fetchPatient.data
    ) {
      const userData = extractUserFromResponse({
        userInfo: fetchUser.data,
        patient: fetchPatient.data,
      })

      setData(userData)
      setIsLoaded(true)
    }
  }, [
    fetchPatient.isFetched,
    fetchPatient.isFetchSuccessful,
    fetchPatient.data,
  ])

  return {
    isLoaded,
    isLoading,
    isAuthenticated,
    data,
  }
}
