import { useStaticQuery, graphql } from 'gatsby'

type Product = {
  id: string
}

type Icon = {
  file: {
    url: string
  }
}

type Category = {
  slug: string
  title: string
  description: string
  icon: Icon
  products: Product[]
}

type CategoriesResponse = {
  contentfulContainer: {
    items: Category[]
  }
}

export default function useMarketplaceCategories(): Category[] {
  const {
    contentfulContainer: { items: categories },
  } = useStaticQuery<CategoriesResponse>(graphql`
    query {
      contentfulContainer(title: { eq: "Marketplace Categories" }) {
        items {
          slug
          title
          description
          icon {
            file {
              url
            }
          }
          products {
            id
          }
        }
      }
    }
  `)

  return categories
}
