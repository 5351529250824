import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
  useContext,
} from 'react'

import { BodyComp } from 'types/bodyComp'
import isBrowser from 'utils/isBrowser'

export type User = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  patientId: string
  birthDate: string
  photo?: string
  bodyComp?: BodyComp
}

type UserContextProps = {
  data: User
  isLoading: boolean
  isLoaded: boolean
  setData: (data: User) => void
  setIsLoading: (value: boolean) => void
  setIsLoaded: (value: boolean) => void
  clear: () => void
}

function getUserFromLocalStorage(): User | null {
  const rawData = isBrowser() && localStorage.getItem('jevittyUser')

  if (rawData) {
    return JSON.parse(rawData) as User
  }

  return null
}

function storeUserInLocalStorage(user: User): void {
  localStorage.setItem('jevittyUser', JSON.stringify(user))
}

export const UserContext = createContext<UserContextProps>(null)

export function useUserContext() {
  return useContext(UserContext)
}

function useUserProvider() {
  const [data, setData] = useState(getUserFromLocalStorage())
  const [isLoaded, setIsLoaded] = useState(!!data)
  const [isLoading, setIsLoading] = useState(false)

  const clear = useCallback(() => {
    setData(null)
    setIsLoaded(false)
    setIsLoading(false)
  }, [setData, setIsLoading, setIsLoading])

  useEffect(() => {
    if (isBrowser()) {
      if (data) {
        storeUserInLocalStorage(data)
      } else {
        localStorage.removeItem('jevittyUser')
      }
    }
  }, [data])

  return {
    data,
    isLoading,
    isLoaded,
    setIsLoading,
    setIsLoaded,
    setData,
    clear,
  }
}

export default function UserProvider({ children }: { children: ReactNode }) {
  const value = useUserProvider()

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
