import axios from 'axios'

const BASE_URL =
  process.env.GATSBY_API_URL ||
  'https://northamerica-northeast1-jevitty.cloudfunctions.net'

export const client = axios.create({
  baseURL: BASE_URL,
})

client.interceptors.request.use(
  config => {
    const token = localStorage.getItem('jevittyToken')

    if (token !== null) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  error => Promise.reject(error),
)
