import { useEffect } from 'react'

import { useFetch } from 'hooks'
import { getAidboxResources } from 'services/api'

type Answer = {
  value: {
    Coding: {
      code: string
    }
  }
}

type Question = {
  answer: Answer[]
  linkId: string
}

type QuestionnaireResponse = {
  id: string
  resourceType: 'QuestionnaireResponse'
  item: Question[]
}

export default function useGender(patientId: string) {
  const { fetch, data, isFetched, isFetching } = useFetch(
    async () =>
      await getAidboxResources<QuestionnaireResponse>('QuestionnaireResponse', {
        source: patientId,
        _count: 1,
        _sort: '-lastUpdated',
      }),
  )

  useEffect(() => {
    if (patientId && !isFetching && !isFetched) fetch()
  }, [])

  let gender: string | undefined

  if (data) {
    const questions = data[0].item
    const genderQuestion = questions.find(({ linkId }) => linkId === 'gender')
    gender = genderQuestion?.answer[0].value.Coding.code
  }

  return { gender: gender || 'Female' }
}
