import { useState, useCallback } from 'react'
import { Response, isFailure, isSuccess } from 'services/api'

export default function useFetch<S = any, F = any>(
  api: (params?: any) => Promise<Response<S, F>>,
) {
  const [isFetching, setIsFetching] = useState(false)
  const [isFetched, setIsFetched] = useState(false)
  const [isFetchSuccessful, setIsFetchSuccessful] = useState(false)
  const [data, setData] = useState<S | null>(null)
  const [error, setError] = useState<F | null>(null)

  const fetch = useCallback(
    (params?: any) => {
      ;(async () => {
        setIsFetching(true)
        setIsFetched(false)
        setIsFetchSuccessful(false)

        const response: Response<S, F> = await api(params)

        setIsFetched(true)
        setIsFetching(false)

        if (isSuccess(response)) {
          setData(response.data)
          setError(null)
          setIsFetchSuccessful(true)
        }

        if (isFailure(response)) {
          setData(null)
          setError(response.error)
          setIsFetchSuccessful(false)
        }
      })()
    },
    [setIsFetching, setIsFetched, setData, setError, setIsFetchSuccessful],
  )

  const reset = useCallback(() => {
    setIsFetched(false)
    setIsFetchSuccessful(false)
    setData(null)
    setError(null)
  }, [setIsFetched, setIsFetchSuccessful, setData, setError])

  return { isFetching, isFetched, isFetchSuccessful, fetch, data, error, reset }
}
