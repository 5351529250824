import api from './api'
import { Failure } from './types'

type Score = {
  score: number
}

export async function getScore() {
  return api<Score, Failure>({
    url: `/$score`,
    method: 'GET',
  })
}
