import React, { Fragment, ReactElement } from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'

import { useLegalDocuments } from 'hooks'

import './Footer.scss'

export default function Footer({
  className,
}: {
  className?: string
}): ReactElement {
  const year = new Date().getFullYear()
  const legalDocuments = useLegalDocuments()

  return (
    <footer className={classNames('footer', className)}>
      <p className="footer__copyright">
        Copyright © {year} - Jevitty Life Science&nbsp;Corp.
      </p>

      <nav className="footer__navigation">
        <Link className="footer__navigation__link" to="/cookie-settings">
          Cookie Settings
        </Link>
        {' • '}
        {legalDocuments &&
          legalDocuments.map(({ slug, title }, index) => {
            const isNotLast = index < legalDocuments.length - 1

            return (
              <Fragment key={index}>
                <Link className="footer__navigation__link" to={`/${slug}/`}>
                  {title}
                </Link>
                {isNotLast && ' • '}
              </Fragment>
            )
          })}
      </nav>
    </footer>
  )
}
