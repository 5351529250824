import api from './api'
import { AidboxResource, AidboxReference, Failure } from './types'

export async function getAidboxResource<R extends AidboxResource>({
  resourceType,
  id,
}: AidboxReference) {
  return api<R, Failure>({
    method: 'GET',
    url: `/${resourceType}/${id}`,
  })
}
