import React, {
  createContext,
  useState,
  ReactNode,
  useCallback,
  useEffect,
} from 'react'

import isBrowser from 'utils/isBrowser'

type AuthContext = {
  token: null | string
  setToken: (token: string) => void
  clearToken: () => void
}

const STORAGE_KEY = 'jevittyToken'

const AuthenticationContext = createContext<AuthContext>(null)

function restoreToken() {
  if (isBrowser()) {
    return localStorage.getItem(STORAGE_KEY)
  }

  return null
}

function updateToken(token) {
  if (isBrowser()) {
    if (token) {
      localStorage.setItem(STORAGE_KEY, token)
    } else {
      localStorage.removeItem(STORAGE_KEY)
    }
  }
}

function useTokenProvider() {
  const [token, setToken] = useState<null | string>(restoreToken())

  const clearToken = useCallback(() => {
    setToken(null)
  }, [setToken])

  useEffect(() => {
    updateToken(token)
  }, [token])

  return {
    token,
    setToken,
    clearToken,
  }
}

export { AuthenticationContext }

export default function AuthProvider({ children }: { children: ReactNode }) {
  const { token, setToken, clearToken } = useTokenProvider()

  return (
    <AuthenticationContext.Provider value={{ token, setToken, clearToken }}>
      {children}
    </AuthenticationContext.Provider>
  )
}
