import { useAuthentication } from '../hooks'

import { useUserContext } from 'contexts/UserProvider'

export default function useUser() {
  const { data, isLoaded, isLoading } = useUserContext()
  const { isAuthenticated, logout } = useAuthentication()

  return {
    isLoaded,
    isLoading,
    isAuthenticated,
    data,
    logout,
  }
}
