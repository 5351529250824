import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { useGtag } from 'hooks'

import './CookieConsentDialog.scss'

export default function CookieConsentDialog() {
  const [isRendered, setIsRendered] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const { grantConsent } = useGtag()

  const DELAY_BEFORE_RENDER = 1000

  useEffect(() => {
    if (localStorage.getItem('enable-analytics') === null) {
      setIsRendered(true)
      setTimeout(() => setIsVisible(true), DELAY_BEFORE_RENDER)
    }
  }, [])

  const handleAccept = () => {
    localStorage.setItem('enable-analytics', 'true')
    grantConsent()
    setIsVisible(false)
    setTimeout(() => setIsRendered(false), 250)
  }

  return isRendered ? (
    <div
      className={classNames('cookie-consent-dialog', {
        'cookie-consent-dialog--visible': isVisible,
      })}
    >
      <p className="cookie-consent-dialog__text">
        By continuing on this site you agree to let us use cookies from Google
        Analytics. We only use those to measure performance, understand our
        audience, and enhance your experience. We do not collect cookies for
        advertising&nbsp;purposes.
      </p>
      <p className="cookie-consent-dialog__text">
        You&nbsp;can always opt-out by clicking
        &lsquo;Cookie&nbsp;Settings&rsquo; at the bottom of any&nbsp;page.
      </p>
      <button
        className="btn btn-primary cookie-consent-dialog__button"
        color="success"
        onClick={handleAccept}
      >
        Accept &amp; Continue
      </button>
    </div>
  ) : null
}
