import api from './api'
import { Failure } from './types'
import { BodyComp } from 'types/bodyComp'

export type UserInfo = {
  active: boolean
  data: {
    patient: { id: string; resourceType: string }
    bodyComp?: BodyComp
  }
  id: string
  email: string
  phoneNumber: string
  resourceType: string
  userType: string
}

export async function getUserInfo() {
  return api<UserInfo, Failure>({
    url: `/auth/userinfo`,
    method: 'GET',
  })
}
