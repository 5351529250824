import Cookies from 'js-cookie'

declare global {
  interface Window {
    gtag: (
      subject: string,
      action: string,
      parameters: Record<string, unknown>,
    ) => void
  }
}

function removeAnalyticsCookies() {
  const analyticsId =
    process.env.GATSBY_GOOGLE_ANALYTICS_ID &&
    process.env.GATSBY_GOOGLE_ANALYTICS_ID.replace('G-', '')

  Cookies.remove('_ga')
  Cookies.remove('_ga', { path: '/', domain: '.jevitty.com' })
  Cookies.remove('_gid')
  Cookies.remove('_gid', { path: '/', domain: '.jevitty.com' })
  if (analyticsId) {
    Cookies.remove(`_ga_${analyticsId}`)
    Cookies.remove(`_ga_${analyticsId}`, {
      path: '/',
      domain: '.jevitty.com',
    })
  }
}

export default function useGtag() {
  function grantConsent() {
    if (typeof window !== 'undefined' && window.gtag)
      window.gtag('consent', 'update', { analytics_storage: 'granted' })
  }
  function denyConsent() {
    if (typeof window !== 'undefined' && window.gtag) {
      removeAnalyticsCookies()
      window.gtag('consent', 'update', { analytics_storage: 'denied' })
    }
  }

  return { grantConsent, denyConsent }
}
