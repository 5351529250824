import api from './api'
import { Failure, AidboxResource } from './types'

export async function getAidboxResources<R extends AidboxResource>(
  resourceType: R['resourceType'],
  params?: any,
) {
  return api<Array<R>, Failure>({
    method: 'GET',
    url: `/${resourceType}`,
    params: {
      _result: 'array',
      ...(params ? params : {}),
    },
  })
}
