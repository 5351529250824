import api from './api'

type PasswordResetSendData = {
  email: string
}

type PasswordResetNewPasswordData = {
  password: string
  code: string
}

interface EmptySuccess {}
type Failure = string

export function passwordResetSend(data: PasswordResetSendData) {
  return api<EmptySuccess, Failure>({
    url: '/auth/$send-reset-password',
    method: 'POST',
    data,
  })
}

export function passwordResetNewPassword(data: PasswordResetNewPasswordData) {
  return api({
    url: 'auth/reset-password',
    method: 'POST',
    data,
  })
}
