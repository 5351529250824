import { useStaticQuery, graphql } from 'gatsby'

export type PostPreviewNode = {
  publishDate: string
  slug: string
  title: string
  mainImage: {
    file: {
      url: string
    }
  }
  description: {
    childMarkdownRemark: {
      html: string
    }
  }
  author: { name: string }
}

type PostPreviewsResponse = {
  postPreviews: {
    nodes: PostPreviewNode[]
  }
}

export type PostPreview = {
  date: string
  slug: string
  title: string
  image: string
  description: string
  author: string
}

export default function useNewsPosts(): PostPreview[] {
  const {
    postPreviews: { nodes: posts },
  } = useStaticQuery<PostPreviewsResponse>(graphql`
    query {
      postPreviews: allContentfulBlogPost(
        sort: { fields: publishDate, order: DESC }
      ) {
        nodes {
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          title
          mainImage {
            file {
              url
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          author {
            name
          }
        }
      }
    }
  `)

  const flattenedPosts = posts.map(
    ({ publishDate, mainImage, description, author, ...rest }) => {
      return {
        ...rest,
        date: publishDate,
        image: mainImage && mainImage.file.url,
        description: description && description.childMarkdownRemark.html,
        author: author && author.name,
      }
    },
  )

  return flattenedPosts
}
