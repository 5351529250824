import { useContext, useCallback } from 'react'
import { navigate } from 'gatsby'

import { AuthenticationContext } from 'contexts/AuthProvider'

const LOGOUT_CLEAR_STORAGE_ITEMS = [
  'jevittyUser',
  'jevittyActivityData',
  'jevittyBodyFatData',
  'jevittyBodyMassIndexData',
  'jevittyExerciseData',
  'jevittyRestingHeartRateData',
  'jevittySleepData',
  'jevittyStepsData',
  'jevittyVO2maxData',
  'jevittyWeightData',
  'jevittyScore',
]

export default function useAuthentication() {
  const { token, setToken, clearToken } = useContext(AuthenticationContext)

  const logout = useCallback(() => {
    clearToken()
    LOGOUT_CLEAR_STORAGE_ITEMS.forEach(item => localStorage.removeItem(item))
    navigate('/dashboard')
  }, [clearToken])

  return {
    token,
    login: setToken,
    logout,
    isAuthenticated: !!token,
  }
}
