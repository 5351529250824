import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'

import Header from './Header'
import Footer from './Footer'
import CookieConsentDialog from 'components/CookieConsentDialog'

import './Layout.scss'

type LayoutProps = {
  className?: string
  children?: ReactNode
  webview?: boolean
}

export default function Layout({
  className,
  children,
  webview,
}: LayoutProps): ReactElement {
  return (
    <main className={classNames('page-layout', className)}>
      {webview ? (
        <div className="container-fluid">{children}</div>
      ) : (
        <>
          <div className="container">
            <Header />
            {children}
            <Footer className="page-layout__footer" />
          </div>

          <CookieConsentDialog />
        </>
      )}
    </main>
  )
}
