import {
  useState,
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
} from 'react'

import { useGtag } from 'hooks'

export default function useCookieSettingsPage() {
  const isBrowser = () => typeof window !== 'undefined'
  const { grantConsent, denyConsent } = useGtag()
  const consentStatus =
    isBrowser() && localStorage.getItem('enable-analytics') === 'true'

  const [switchIsEnabled, setSwitchIsEnabled] = useState<boolean>(consentStatus)
  const [saveButtonIsDisabled, setSaveButtonIsDisabled] = useState<boolean>(
    consentStatus === switchIsEnabled,
  )
  const [isSaved, setIsSaved] = useState<boolean>(false)

  const handleSwitchChange: ChangeEventHandler<HTMLInputElement> = e => {
    setSwitchIsEnabled(e.target.checked)
  }

  const handleSave: MouseEventHandler<HTMLButtonElement> = () => {
    if (isBrowser()) {
      localStorage.setItem('enable-analytics', `${switchIsEnabled}`)
      setSaveButtonIsDisabled(true)
      setIsSaved(true)
    }

    if (switchIsEnabled) grantConsent()
    else denyConsent()
  }

  useEffect(() => {
    setSaveButtonIsDisabled(consentStatus === switchIsEnabled)
  }, [switchIsEnabled, consentStatus])

  useEffect(() => {
    if (isSaved && consentStatus !== switchIsEnabled) setIsSaved(false)
  }, [isSaved, switchIsEnabled, consentStatus])

  return {
    switchIsEnabled,
    handleSwitchChange,
    handleSave,
    saveButtonIsDisabled,
    isSaved,
  }
}
